/* eslint-disable camelcase */
import API_ENDPOINT from '../global/api-endpoint';

class AppSource {
  static async _execute(endpoint, options) {
    try {
      const response = await fetch(endpoint, options);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      const data = {
        error: true,
        data: error,
      };
      return data;
    }
  }

  static login({ username, password }) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    };
    return this._execute(API_ENDPOINT.LOGIN, options);
  }

  static resetPassword({ username }) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username }),
    };
    return this._execute(API_ENDPOINT.RESET_PASSWORD, options);
  }

  static getPayment(data) {
    const options = {
      method: 'GET',
      headers: { Authorization: `${data.token}` },
    };
    return this._execute(API_ENDPOINT.PAYMENTS_OFFICER(data), options);
  }

  static searchPayment({ token, query }) {
    const options = {
      method: 'GET',
      headers: { Authorization: `${token}` },
    };
    return this._execute(API_ENDPOINT.SEARCH_PAYMENT(query), options);
  }

  static detailPayment({ token, id }) {
    const options = {
      method: 'GET',
      headers: { Authorization: `${token}` },
    };
    return this._execute(API_ENDPOINT.DETAIL_PAYMENT(id), options);
  }

  static createPaymentRequest({ token, nim, nominal }) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nim, nominal }),
    };
    return this._execute(API_ENDPOINT.REQ_PAYMENT, options);
  }

  static paymentAccepted({
    token, student_nim, status,
  }) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ student_nim, status }),
    };
    return this._execute(API_ENDPOINT.ACC_PAYMENT, options);
  }
}

export default AppSource;
