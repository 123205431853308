import CONFIG from './config';

const API_ENDPOINT = {
  LOGIN: `${CONFIG.BASE_URL_SOURCE}/login`,
  RESET_PASSWORD: `${CONFIG.BASE_URL_SOURCE}/resetpassword`,
  ACC_PAYMENT: `${CONFIG.BASE_URL_SOURCE}/acceptpayment`,
  REQ_PAYMENT: `${CONFIG.BASE_URL_SOURCE}/reqpayment`,
  PAYMENTS_STUDENT: `${CONFIG.BASE_URL_SOURCE}/payment`,
  PAYMENTS_OFFICER: ({
    reqOnly, page,
  }) => `${CONFIG.BASE_URL_SOURCE}/payment?req_only=${reqOnly}&page=${page}`,
  SEARCH_PAYMENT: (query) => `${CONFIG.BASE_URL_SOURCE}/payment/search/q/${query}`,
  DETAIL_PAYMENT: (id) => `${CONFIG.BASE_URL_SOURCE}/payment/detail/${id}`,
};

export default API_ENDPOINT;
